import {FormProperties} from "./formInterfaces";
import {contact} from "./data/contact";
import {apply} from "./data/apply";
import {event_apply} from "./data/event_apply";

const formProperties: FormProperties[] = [
    {
        path: 'contact',
        formData: contact
    },
    {
        path: 'apply',
        formData: apply
    },
    {
        path: 'event-apply',
        formData: event_apply
    },
]

export function getFormProperties(): FormProperties[] {
    return formProperties
}