import {FormData} from "../formInterfaces";
import * as Yup from "yup";

export const event_apply: FormData = {
    steps: [
        {
            label: "What is your Minecraft username?",
            name: "username",
            type: "text",
            min_length: 3,
            max_length: 16,
            required: true,
        },
        {
            label: "What is your email?",
            name: "email",
            type: "email",
            min_length: 3,
            max_length: 254,
            required: true,
        },
        {
            label: "What is your Discord username?",
            name: "discord",
            type: "text",
            min_length: 2,
            max_length: 32,
            required: true,
        },
        {
            label: "What is your age?",
            name: "age",
            type: "text",
            min_length: 1,
            max_length: 3,
            required: true,
        },
        {
            label: "What is your preferred pronoun?",
            name: "pronoun",
            type: "dropdown",
            min_length: 0,
            max_length: 16,
            required: true,
            drop_down: ["They/them", "She/her", "He/him", ""],
        },
        {
            label: "On average, how many hours per week are you available to help with events?",
            name: "avg_time",
            type: "text",
            min_length: 1,
            max_length: 2,
            required: true,
        },
        {
            label: "Do you have any previous experience running events of any kind?",
            name: "event_experience",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "What things are you good at building?",
            additional_info: "You can include links to pictures of your builds",
            name: "good_builds",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "What things do you struggle to build?",
            name: "bad_builds",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "Do you have experience with the technical aspects of Minecraft? If so please list what you can do",
            additional_info: "With technical aspects we mean things like plugins, mods, adventure maps, etc.",
            name: "technical_aspects",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "Are you able to use voice chat on Discord",
            name: "discord_vc",
            type: "dropdown",
            min_length: 2,
            max_length: 3,
            required: true,
            additional_info: "Use voice chat in Discord with reasonable audio quality\n" +
                "Take screenshots through your pc (normally f2 in minecraft)\n" +
                "Record your screen through your pc at 20fps+ and 720p+ at normal Minecraft settings (with free programs like OBS)\n",
            drop_down: ["Yes", "No", ""],
        },
        {
            label: "You may share anything else you wish here.",
            name: "other",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: false,
        },
    ],
    backend: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/event-apply/eventApplication`,
    userInput: {
        username: '', email: '', discord: ''
        , age: '', pronoun: '', avg_time: '', event_experience: ''
        , discord_vc: '', good_builds: '', bad_builds: '', technical_aspects: '', other: ''
    },
    spec: Yup.object().shape({
        username: Yup.string()
            .min(3, 'Username should be at least 3 characters')
            .max(16, 'Username should not exceed 16 characters')
            .matches(/^[a-zA-Z0-9_]*$/, 'Username should only include alphanumeric characters and underscore')
            .required('Please enter your username'),

        email: Yup.string()
            .email('Invalid email')
            .min(3, 'Email should be at least 3 characters')
            .max(254, 'Email should not exceed 254 characters')
            .required('Please enter your email'),

        discord: Yup.string()
            .min(2, 'Discord name should be at least 2 characters')
            .max(32, 'Discord name should be at most 32 characters')
            .matches(/^(?!.*\.\.)([a-z0-9._]{2,32})$/, 'Please enter a valid Discord name')
            .required('Please enter your Discord name'),

        age: Yup.number()
            .typeError('Input must be a number')
            .integer('Please enter a whole number')
            .min(0, 'Please enter a valid age')
            .max(999, 'We do not accept players older than 999 years old sorry!')
            .required('You are required to fill out your age'),

        pronoun: Yup.string()
            .max(16, 'Pronouns can\'t be longer than 16 characters'),

        avg_time: Yup.number()
            .typeError('Please enter a number')
            .integer('Please enter a whole number')
            .min(0, 'Please enter a positive number')
            .max(168, 'There are only 168 hours in a week')
            .required('Please enter the average time you will be available each week'),

        event_experience: Yup.string()
            .min(2, 'Please provide your experience with running/working on events, if you don\'t have any you can say that instead')
            .max(2000, 'Please provide at most 2000 characters')
            .required('Please enter your event experience or simply say you don\'t have any'),

        discord_vc: Yup.string()
            .matches(/(yes|no)$/i, 'Yes or no')
            .required('An answer is required'),

        good_builds: Yup.string()
            .min(2, 'Please provide examples of things you are good at building')
            .max(2000, 'Please provide at most 2000 characters')
            .required('Please enter your build experience or simply say you don\'t have any'),

        bad_builds: Yup.string()
            .min(2, 'Please provide examples of things you are bad at building')
            .max(2000, 'Please provide at most 2000 characters')
            .required('Please enter your build experience or simply say you don\'t have any'),

        technical_aspects: Yup.string()
            .min(2, 'Please provide your experience with the technical aspects of Minercraft, if you don\'t have any you can say that instead')
            .max(2000, 'Please provide at most 2000 characters')
            .required('Please enter your technical experience or simply say you don\'t have any'),

        other: Yup.string()
            .max(2000, 'Please provide at most 2000 characters')
    }),
    title: "Event Application",
    backendFormName: "EventApplication",
};